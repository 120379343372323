<template>
  <div class="paginationPageBox">
    <el-pagination
      :current-page="currentPage4"
      :pager-count="5"
      background
      :page-sizes="pageSizes"
      :layout="layoutOption"
      :total="total"
      :hide-on-single-page="true"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <div v-show="inArticle !== 0" class="numberBranches">
      显示第
      <span>{{ params.pageSize * (params.pageNum - 1) + 1 }}</span> 到第
      <span>{{ inArticle + params.pageSize * (params.pageNum - 1) }}</span>
      条记录，总共 <span>{{ total }}</span> 条记录
    </div>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      // 总条数
      type: Number,
      default: 0
    },
    layoutOption: {
      type: String,
      default: 'prev, pager, next, sizes, jumper, total'
    },
    params: {
      // 分页的页码和页的条数
      type: Object,
      default: () => {
        return { pageSize: 10, pageNum: 1 }
      }
    },
    inArticle: {
      // 到多少条的数据
      type: Number,
      default: 0
    },
    getDataList: {
      // 操作数据的函数
      type: Function,
      default: () => {}
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 30, 40, 50, 60, 80, 100]
    }
  },
  data() {
    return {
      currentPage4: 0
    }
  },
  methods: {
    // 点击页数或者行数的函数
    handleSizeChange(page) {
      this.params.pageSize = page
      this.getDataList()
    },
    handleCurrentChange(page) {
      this.params.pageNum = page
      this.currentPage4 = page
      // 当时点击页数的时候就传一个参数过去，判断不用回到第一页，其他的
      // 时候都需要回到第一页
      this.getDataList(true)
    }
  }
}
</script>
<style lang="scss">
:root{
  --primary-theme-color:#D31E0E;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item.selected,.el-scrollbar .el-scrollbar__view .el-select-dropdown__item.hover{
    color: var(--primary-theme-color)!important;
  }
.paginationPageBox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    background-color: #fff;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    height: 36px;
    line-height: 36px;
    padding: 0 14px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    color: #ebebeb;
    background-color: var(--primary-theme-color);
  }
  // .el-pagination.is-background .el-pager li:not(.disabled):hover,
  .el-pager li.active,
  .el-pager li:hover,
  .el-pagination button:hover {
    color: var(--primary-theme-color);
  }
  .el-input--mini .el-input__inner {
    height: 36px;
    line-height: 36px;
  }
  .el-select .el-input__inner:focus,
  .el-select .el-input.is-focus .el-input__inner {
    border: 1px solid var(--primary-theme-color);
  }
  .el-pagination__total {
    margin-left: 12px;
    height: 36px !important;
    line-height: 36px !important;
  }
  .el-pagination__editor.el-input .el-input__inner {
    height: 36px;
    line-height: 36px;
  }
  .el-pagination__sizes .el-input .el-input__inner:hover {
    border: 1px solid var(--primary-theme-color);
  }
  .el-pagination__editor.el-input .el-input__inner:focus {
    border: 1px solid var(--primary-theme-color);
  }
}
</style>
